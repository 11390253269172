import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import 'moment/locale/fr'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Loader from '../components/Loader'
import { findCompany } from '../api'
import Button from '../components/Button'
import Auth from '../components/Auth'
moment.locale('fr')

const match = (typeof window !== `undefined`) ? (window.location.pathname.match(/dashboard\/(\d+)_(.+)/) || []) : []
const companyId = match[1]
const token = match[2]
const screenWidth = (typeof window !== `undefined`) ? window.innerWidth : 0

const percentColor = percent => `hsl(${Math.max(Math.min(percent * -210 + 250, 210), 0)}deg, ${percent * 90 + 10}%, 50%)`

const labelStyle = 'mt-8 italic text-center lg:text-left'
const figureBlockStyle = 'w-12 h-12 lg:w-16 lg:h-16 flex flex-col leading-none rounded justify-center items-center text-white text-base text-shadow font-bold'
const figureWrapperStyle = 'flex flex-col justify-center items-center text-center m-1 lg:m-0 lg:mr-4'
const legendStyle = 'text-xs lg:text-sm lg:font-bold'

const downloadCSV = table => {
  let csv = `data:text/csv;charset=utf-8,${table.join('\n')}`
  const link = document.createElement('a')
  link.setAttribute('href', encodeURI(csv))
  link.setAttribute('download', `export_${moment().format('DD-MM-YYYY')}.csv`)
  document.body.appendChild(link)
  link.click()
}

function DashboardPage() {
  const [company, setCompany] = useState()
  const [dates, setDates] = useState({ start: moment().startOf('week'), end: moment() })
  const [focus, setFocus] = useState()
  const [boothsInfos, setBoothsInfos] = useState()
  useEffect(() => { findCompany(companyId, token).then(c => setCompany({ ...c, payments: c.payments.sort((a, b) => moment(a.created_at).diff(moment(b.created_at))) })) }, [])
  useEffect(() => fetch('https://cache.wizito.com/data-8755833804830dd038fe2b144085a137').then(res => res.json()).then(setBoothsInfos), [])
  if (!company) return <Loader />

  const payments = company.payments
  const filteredDatePayments = payments.filter(p => moment(p.created_at).isBetween(dates.start && dates.start.clone().set('hour', '1'), dates.end && dates.end.clone().set('hour', '23')))
  const groupByDays = filteredDatePayments.reduce((acc, cur) => {
    acc[moment(cur.created_at).day()]++
    return acc
  }, { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 })
  const groupByHours = filteredDatePayments.reduce((acc, cur) => {
    acc[moment(cur.created_at).hour()] = (acc[moment(cur.created_at).hour()] || 0) + 1
    return acc
  }, {})
  const groupByBooth = filteredDatePayments.reduce((acc, cur) => {
    acc[cur.booth] = [...acc[cur.booth], cur]
    return acc
  }, company.booths.reduce((acc, curr) => ({ ...acc, [curr.id]: [] }), {}) )

  const formats = filteredDatePayments.map(p => p.content.reduce((acc, curr) => ({ ...acc, [curr.product]: (acc[curr.product] || 0) + curr.quantity }), {}))
  .reduce((acc, curr) => { Object.keys(curr).forEach(k => acc[k] = (acc[k] || 0) + curr[k]); return acc }, {})
  const emails = [...new Set(payments.filter(p => p.email && p.optin && p.email.match(/.{3,}@/)).map(p => p.email))]
  const ticketsCB = [`Date;Montant;Ticket CB`, ...payments.filter(p => p.ticket).map(p => ([p.created_at, p.amount, `"${p.ticket}"`].join(';')))]
  
  return (
    <div className="text-black mx-auto w-screen max-w-screen-lg px-4 lg:px-0">
      <div className="text-2xl lg:text-3xl font-medium text-center mt-4">Suivi des ventes Wizito</div>
      <img src={company.logo.url} className="block h-24 lg:h-32 mx-auto" />
      <div className="flex justify-center">
        <Button variant="secondary" onClick={() => downloadCSV(emails)} className="bg-blue-700 bg-opacity-100 mb-4 mt-2 mx-2 font-bold">Télécharger les {emails.length} emails</Button>
        <Button variant="secondary" onClick={() => downloadCSV(ticketsCB)} className="bg-blue-700 bg-opacity-100 mb-4 mt-2 mx-2 font-bold">Télécharger les tickets CB</Button>
      </div>
      <div>
        <div className="flex flex-wrap italic mb-2">
          <div className="flex-grow w-full">Filtre par date</div>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().startOf('week'), end: moment() })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            S
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().subtract(1, 'week').startOf('week'), end: moment().subtract(1, 'week').endOf('week') })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            S-1
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().subtract(2, 'week').startOf('week'), end: moment().subtract(2, 'week').endOf('week') })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            S-2
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().subtract(3, 'week').startOf('week'), end: moment().subtract(3, 'week').endOf('week') })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            S-3
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().startOf('month'), end: moment() })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            M
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().subtract(1, 'month').startOf('month'), end: moment().subtract(1, 'month').endOf('month') })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            M-1
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().subtract(2, 'month').startOf('month'), end: moment().subtract(2, 'month').endOf('month') })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            M-2
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment().subtract(3, 'month').startOf('month'), end: moment().subtract(3, 'month').endOf('month') })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            M-3
          </Button>
          <Button
            variant="secondary"
            onClick={() => setDates({ start: moment(payments[0].created_at), end: moment() })}
            className="bg-blue-700 bg-opacity-100 mr-1 mb-1 lg:mr-2 font-bold">
            Tout
          </Button>
        </div>
        <style dangerouslySetInnerHTML={{ __html: `
          .DateInput_input__focused {     border-bottom: 2px solid #366cb0; }
          .CalendarDay__selected, .CalendarDay__selected:hover { background: #366cb0; border-color: #366cb0; }
          .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover { background: #366cb0; border-color: #366cb0 }
          .CalendarDay__selected_span { background: #93b5e0ed; border-color: #93b5e0; }
        ` }} />
        <DateRangePicker
          startDate={dates.start}
          startDateId="your_unique_start_date_id"
          endDate={dates.end}
          endDateId="your_unique_end_date_id"
          onDatesChange={({ startDate, endDate }) => { setDates({ start: startDate, end: endDate }); setFocus('startDate') } }
          focusedInput={focus}
          onFocusChange={e => setFocus(e)}
          isOutsideRange={d => !d.isBetween(moment(payments[0].created_at), moment().add(1, 'day'))}
          firstDayOfWeek={1}
          numberOfMonths={screenWidth > 1000 ? 2 : 1}
          minimumNights={0}
          small={screenWidth < 1000}
          showClearDates
          reopenPickerOnClearDates
          keepOpenOnDateSelect
          hideKeyboardShortcutsPanel />
      </div>
      <div className={labelStyle}>Infos bornes</div>
      <div className="m-2 flex flex-wrap justify-center lg:justify-start">
      {boothsInfos &&
        boothsInfos.filter(b => b.name.match(/CHEERZ/)).sort((a, b) => a.name.localeCompare(b.name)).map(booth =>
          <div className={`mr-4 mb-4 w-40 h-20 rounded ${booth.live ? 'bg-blue' : 'bg-gray-300'} text-white leading-none flex flex-col justify-center items-center`}>
            {/* <div className="text-xl">{company.booths.find(b => `${b.name}` === booth.name).address.split(',')[0].replace('Monop ', '')}</div> */}
            <div className="text-xs text-center mt-1">dernière session <br/> {moment(booth.last_session_date).fromNow()}</div>
            <div className="text-xs text-center mt-1">{booth.printer_status} - {booth.remaining_prints}</div>
          </div>
        )
      }
      </div>

      {dates.end && dates.start &&
        <div>
          <div className={labelStyle}>Indicateurs généraux</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start">
            <div className="mr-4 mb-4 w-40 h-20 rounded bg-gray-700 text-white leading-none flex flex-col justify-center items-center">
              <div className="text-3xl font-bold">{filteredDatePayments.reduce((acc, cur) => cur.amount + acc, 0) / 100.0}</div>
              <div>euros</div>
            </div>
            <div className="mr-4 mb-4 w-40 h-20 rounded bg-gray-700 text-white leading-none flex flex-col justify-center items-center">
              <div className="text-3xl font-bold">{filteredDatePayments.length}</div>
              <div>sessions</div>
            </div>
            <div className="mr-4 mb-4 w-40 h-20 rounded bg-gray-700 text-white leading-none flex flex-col justify-center items-center">
              <div className="text-3xl font-bold">{(filteredDatePayments.reduce((acc, cur) => cur.amount + acc, 0) / 100.0 / filteredDatePayments.length).toFixed(2)}</div>
              <div>euros / sessions</div>
            </div>
            <div className="mr-4 mb-4 w-40 h-20 rounded bg-gray-700 text-white leading-none flex flex-col justify-center items-center">
              <div className="text-3xl font-bold">{(filteredDatePayments.length / dates.end.diff(dates.start, 'days')).toFixed(2)}</div>
              <div>sessions / jour</div>
            </div>
          </div>
          <div className={labelStyle}>Répartition des sessions par jour de la semaine</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start">
            {Object.keys(groupByDays).filter(d => d !== '0').map(day =>
              <div className={figureWrapperStyle}>
                <div className={legendStyle}>{['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'][day]}</div>
                <div
                  className={figureBlockStyle}
                  style={{ backgroundColor: percentColor(groupByDays[day] / filteredDatePayments.length * 3) }}>
                    <strong className="text-lg">{Math.round(groupByDays[day] / filteredDatePayments.length * 100)} %</strong>
                    <div className="text-sm">{groupByDays[day]}</div>
                </div>              
              </div>
            )}
          </div>
          <div className={labelStyle}>Répartition des sessions par heure de la journée</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start">
            {Object.keys(groupByHours).map(hour =>
              <div className={figureWrapperStyle}>
                <div className={legendStyle}>{hour}h</div>
                <div
                  className={figureBlockStyle}
                  style={{ backgroundColor: percentColor(groupByHours[hour] / filteredDatePayments.length * 3) }}>
                    <strong className="text-lg">{Math.round(groupByHours[hour] / filteredDatePayments.length * 100)} %</strong>
                    <div className="text-sm">{groupByHours[hour]}</div>
                </div>              
              </div>
            )}
          </div>
          <div className={labelStyle}>Répartition des sessions par site</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start">
            {Object.keys(groupByBooth).filter(p => groupByBooth[p].length !== 0).map(booth =>
              <div className={figureWrapperStyle}>
                <div className={legendStyle}>{company.booths.find(b => `${b.id}` === booth).address.split(',')[0].replace('Monop ', '')}</div>
                <div
                  className={figureBlockStyle}
                  style={{ backgroundColor: percentColor(groupByBooth[booth].length / filteredDatePayments.length * 3) }}>
                    <strong>{Math.round(groupByBooth[booth].length / filteredDatePayments.length * 100)} %</strong>
                    <div className="text-sm">{groupByBooth[booth].length}</div>
                </div>              
              </div>
            )}
          </div>
          <div className={labelStyle}>Euros par site</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start">
            {Object.keys(groupByBooth).filter(p => groupByBooth[p].length !== 0).map(booth =>
              <div className={figureWrapperStyle}>
                <div className={legendStyle}>{company.booths.find(b => `${b.id}` === booth).address.split(',')[0].replace('Monop ', '')}</div>
                <div
                  className={figureBlockStyle}
                  style={{ backgroundColor: percentColor(groupByBooth[booth].reduce((acc, cur) => cur.amount + acc, 0) / 1500.0) }}>
                    <strong>{(groupByBooth[booth].reduce((acc, cur) => cur.amount + acc, 0) / 100.0).toFixed(2)}</strong>
                </div>
              </div>
            )}
          </div>
          <div className={labelStyle}>Euros / sessions par site</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start">
            {Object.keys(groupByBooth).filter(p => groupByBooth[p].length !== 0).map(booth =>
              <div className={figureWrapperStyle}>
                <div className={legendStyle}>{company.booths.find(b => `${b.id}` === booth).address.split(',')[0].replace('Monop ', '')}</div>
                <div
                  className={figureBlockStyle}
                  style={{ backgroundColor: percentColor(groupByBooth[booth].reduce((acc, cur) => cur.amount + acc, 0) / groupByBooth[booth].length / 200.0) }}>
                    <strong>{(groupByBooth[booth].reduce((acc, cur) => cur.amount + acc, 0) / 100.0 / groupByBooth[booth].length).toFixed(2)}</strong>
                </div>
              </div>
            )}
          </div>
          <div className={labelStyle}>Session / jour par site</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start">
            {Object.keys(groupByBooth).filter(p => groupByBooth[p].length !== 0).map(booth =>
              <div className={figureWrapperStyle}>
                <div className={legendStyle}>{company.booths.find(b => `${b.id}` === booth).address.split(',')[0].replace('Monop ', '')}</div>
                <div
                  className={figureBlockStyle}
                  style={{ backgroundColor: percentColor(groupByBooth[booth].length / dates.end.diff(dates.start, 'days') / 10) }}>
                    <strong>{(groupByBooth[booth].length / dates.end.diff(dates.start, 'days')).toFixed(2)}</strong>
                </div>              
              </div>
            )}
          </div>
          <div className={labelStyle}>Formats</div>
          <div className="m-2 flex flex-wrap justify-center lg:justify-start mb-16">
            {Object.keys(formats).map(formatName =>
              <div className={figureWrapperStyle}>
                <div className={legendStyle}>{formatName}</div>
                <div
                  className={figureBlockStyle}
                  style={{ backgroundColor: percentColor(formats[formatName] / Object.keys(formats).reduce((acc, curr) => acc + formats[curr], 0)) }}>
                    <strong>{(formats[formatName] / Object.keys(formats).reduce((acc, curr) => acc + formats[curr], 0)).toFixed(2) * 100} %</strong>
                    <div className="text-sm">{formats[formatName]}</div>
                </div>              
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )
}
export default function AuthDashboardPage() {
  return (
    <Auth>
      <DashboardPage />
    </Auth>
  )
}

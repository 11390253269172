import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { auth } from '../api'
import Button from './Button'
import Label from './Label'
import Popup from './Popup'
import Gradient from './Gradient'
import { Helmet } from 'react-helmet'

export default function Auth({ children }) {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [jwt, setJwt] = useState(Cookies.get('wzt-jwt'))
  if (jwt) return <div>{children}</div>
  const connect = async () => {
    const userData = await auth(user, password)
    Cookies.set('wzt-jwt', userData.jwt, { expires: 7 })
    setJwt(userData.jwt)
  }
  return (
    <div>
      <style dangerouslySetInnerHTML={{ __html: `html { font-size: 125% }` }} />
      <Popup title="Connexion">
        <form className="text-left" onSubmit={e => e.preventDefault()}>
          <Label title="Utilisateur">
            <input type="text" value={user} onChange={e => setUser(e.target.value)} />
          </Label>
          <Label title="Mot de passe">
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
          </Label>
          <Button onClick={connect} className="mt-5 mx-auto" submit>Valider</Button>
        </form>
      </Popup>
      <Gradient />
    </div>
  )
}
